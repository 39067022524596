export function ActivityPricing({ activity }) {
  if (!activity.fees.resident && !activity.fees.non_resident) {
    return <div>Fee: N/A</div>;
  }

  const { primaryAmount, memberAmount, primaryAmountFormatted, memberAmountFormatted } = getPricingFromFees(
    activity.fees
  );

  if (!primaryAmount) {
    return <div>Fee: N/A</div>;
  }

  return (
    <div className="text-sm">
      {memberAmount && (
        <p className="text-nowrap">
          Resident: <span className="tabular-nums tracking-tight">{memberAmountFormatted}</span>
        </p>
      )}
      {primaryAmount && (
        <p className="text-nowrap">
          {primaryAmount && memberAmount && "Non-Resident:"}{" "}
          <span className="tabular-nums tracking-tight">{primaryAmountFormatted}</span>
        </p>
      )}
    </div>
  );
}

function getPricingFromFees(fees) {
  const primaryAmount = fees.non_resident;
  const memberAmount = fees.resident;
  const unitOfMeasure = fees.unit_of_measure;
  const minimumFractionDigits = Number.isInteger(primaryAmount) ? 0 : 2;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: 2,
  });

  let primaryAmountFormatted = formatter.format(primaryAmount);
  let memberAmountFormatted = memberAmount ? formatter.format(memberAmount) : null;

  if (unitOfMeasure) {
    primaryAmountFormatted += ` / ${unitOfMeasure}`;
    memberAmountFormatted += ` / ${unitOfMeasure}`;
  }

  return {
    primaryAmount,
    primaryAmountFormatted,
    memberAmount,
    memberAmountFormatted,
  };
}
