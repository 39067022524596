import { cn } from "@/lib/utils";
import { CalendarClock } from "lucide-react";

export function ActivityDays({ activity }) {
  const recurrences = activity.activity_recurrences;
  if (!recurrences?.length) {
    return null;
  }
  const firstRecurrence = recurrences[0];
  const days = firstRecurrence.days.split(",");

  type Option = {
    label: string;
    value: string;
  };

  const options: Option[] = [
    {
      label: "Mo",
      value: "Monday",
    },
    {
      label: "Tu",
      value: "Tuesday",
    },
    {
      label: "We",
      value: "Wednesday",
    },
    {
      label: "Th",
      value: "Thursday",
    },
    {
      label: "Fr",
      value: "Friday",
    },
    {
      label: "Sa",
      value: "Saturday",
    },
    {
      label: "Su",
      value: "Sunday",
    },
  ];

  function getLabel(day) {
    return options.find((option) => option.value === day)?.label;
  }

  return (
    <div className="flex items-center gap-1">
      <CalendarClock className="w-4" />
      <ul className="flex divide-x divide-theme-green-2/20 border border-theme-green-2/20 rounded-md overflow-hidden">
        {/* {days.map((day) => {
          return (
            <li
              key={day}
              className={cn(
                "bg-primary/20 text-xs text-center p-1 rounded-full size-6 aspect-square items-center justify-center flex "
              )}
            >
              {getLabel(day)}
            </li>
          );
        })} */}
        {options.map((option) => {
          return (
            <li
              key={option.value}
              className={cn("text-xs text-center p-1 size-6 aspect-square items-center justify-center flex ", {
                "bg-primary/20": days.includes(option.value),
                "opacity-50": !days.includes(option.value),
              })}
            >
              {option.label}
            </li>
          );
        })}
      </ul>
    </div>
  );

  // const days = activity.days.map((day) => day.day_name);
  // return <p>{days.join(", ")}</p>;
}
