import { MapPin } from "lucide-react";

export function ActivityLocation({ activity }) {
  const place = activity.place;

  if (!place) {
    return <p>No Location</p>;
  }

  return (
    <div>
      <p className="flex items-center gap-1 text-sm">
        <MapPin className="w-4" /> {place.name}
      </p>
      {/* <p>{place.address1}</p>
            <p>
              {place.city}, {place.state} {place.zip}
            </p> */}
    </div>
  );
}
