import { ActivityCard } from "@/components/activities/activity-card";
import { ActivitiesFilter } from "@/components/activities/activities-filter";
import Fuse from "fuse.js";
import { cn } from "~/lib/utils";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { useState } from "react";

export function ActivityBrowser({ activities, categories, filters, showFilters = true, showCategorySearch = false }) {
  const [stateFilters, setFilters] = useState(filters);

  function handleFilterChange(newFilters) {
    // add query params to URL
    const params = new URLSearchParams();

    // loop through filters and add to query params.
    // Check if the value is an array and join it with a comma
    for (const [key, value] of Object.entries(newFilters)) {
      if (Array.isArray(value) && value.length) {
        params.set(key, value.join(","));
      } else if (value) {
        params.set(key, value);
      }
    }

    // remove any empty query params
    for (const [key, value] of params.entries()) {
      if (!value) {
        params.delete(key);
      }
    }

    setFilters(newFilters);

    // update the URL with the new query params
    // const newUrl = `${window.location.pathname}?${params.toString()}`;
    // window.history.pushState({}, "", newUrl);
  }

  const filteredActivities = filterActivities(activities, stateFilters);

  // group activities by category
  const groupedActivities = groupAndSortActivities(filteredActivities);

  return (
    <div
      className={cn("grid grid-cols-1 lg:grid-cols-3 gap-6", {
        "lg:grid-cols-2": !showFilters,
      })}
    >
      {showFilters && (
        <div className="col-span-1">
          <ActivitiesFilter categories={categories} filters={filters} onChange={handleFilterChange} />
        </div>
      )}
      <div className="col-span-2">
        <ul className="grid gap-6">
          {showCategorySearch && (
            <div className="mt-2" data-no-index>
              <Label htmlFor="category_search">Search within {filters.category[0] ?? " this category"}</Label>
              <Input
                type="text"
                name="filters_search"
                autoComplete="new-password"
                id="category_search"
                defaultValue={filters.search}
                placeholder="Search activities"
                onChange={(e) => {
                  handleFilterChange({ ...filters, search: e.target.value });
                }}
              />
            </div>
          )}
          {filteredActivities.length === 0 && (
            <li className="bg-yellow-100 rounded p-3">
              <p>No activities found.</p>
            </li>
          )}
          {Object.entries(groupedActivities).map(([category, activities]) => {
            return (
              <li key={category}>
                <h2
                  className={cn("text-xl font-semibold bg-primary text-primary-foreground px-4 py-2", {
                    "sticky top-0": showFilters === true,
                  })}
                >
                  {category}
                </h2>
                <ul className="grid gap-3 mt-2">
                  {activities.map((activity) => {
                    return (
                      <li key={activity.id} className="border  rounded p-4 bg-white">
                        <ActivityCard activity={activity} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function groupAndSortActivities(activities) {
  const groupedActivities = activities.reduce((acc, activity) => {
    const category = activity.category || "Misc";

    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(activity);
    return acc;
  }, {});

  // sort groups by category name
  Object.keys(groupedActivities)
    .sort()
    .forEach((key) => {
      const value = groupedActivities[key];
      delete groupedActivities[key];
      groupedActivities[key] = value;
    });

  return groupedActivities;
}

function filterActivities(activities, filters) {
  let filtered = activities.filter((activity) => {
    if (filters.category.length) {
      return filters.category.includes(activity.category);
    }
    return true;
  });

  filtered = removeActivitesWithNoFees(filtered);

  if (filters.search) {
    const options = {
      includeScore: true,
      threshold: 0.2,
      keys: ["activity_name", "activity_number", "activity_id"],
    };

    const fuse = new Fuse(filtered, options);
    const searchResults = fuse.search(filters.search);
    filtered = searchResults.map((result) => result.item);
  }

  return filtered;
}

function removeActivitesWithNoFees(activities) {
  return activities.filter((activity) => {
    if (!activity.fees.resident && !activity.fees.non_resident) {
      return false;
    }

    return true;
  });
}
