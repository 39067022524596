import { Calendar } from "lucide-react";

export function ActivityDates({ activity }) {
  const beginningDate = new Date(activity.default_beginning_date);
  const endingDate = new Date(activity.default_ending_date);

  const formatter = new Intl.DateTimeFormat("en", {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  let dateText = formatter.format(beginningDate);

  if (beginningDate.toISOString() !== endingDate.toISOString()) {
    dateText += " - " + formatter.format(endingDate);
  }

  return (
    <p className="flex items-center gap-1 text-sm">
      <Calendar className="w-4" />
      {dateText}
    </p>
  );
}
