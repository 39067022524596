"use client";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Checkbox } from "../ui/checkbox";

export function ActivitiesFilter({ categories, filters, onChange }) {
  const selectedCategoryNames = new Set(filters.category);

  function setFilterProperty(property, value) {
    onChange({ ...filters, [property]: value });
  }
  return (
    <div className="grid gap-6 sticky top-6 border rounded p-4 max-h-[90vh] overflow-scroll bg-white">
      <div className="grid gap-6">
        <div>
          <Label htmlFor="search" className="font-semibold">
            Search
          </Label>
          <Input
            type="text"
            name="filters_search"
            autoComplete="new-password"
            id="search"
            defaultValue={filters.search}
            onChange={(e) => {
              setFilterProperty("search", e.target.value);
            }}
          />
        </div>
        <div>
          <Label htmlFor="category" className="font-semibold">
            Category
          </Label>
          <div className="grid gap-2 mt-2">
            {categories.map((category) => {
              const categoryName = category.activity_category_name;
              return (
                <div key={category.id} className="flex gap-2">
                  <Checkbox
                    name="category"
                    value={categoryName}
                    id={category.id}
                    defaultChecked={filters.category.includes(categoryName)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        selectedCategoryNames.add(categoryName);
                      } else {
                        selectedCategoryNames.delete(categoryName);
                      }
                      setFilterProperty("category", Array.from(selectedCategoryNames));
                    }}
                  />
                  <Label htmlFor={category.id}>{categoryName}</Label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
