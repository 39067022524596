import { cn } from "@/lib/utils";
import { Clock } from "lucide-react";

export function ActivityTimes({ activity }) {
  const recurrences = activity.activity_recurrences;
  if (!recurrences?.length) {
    return null;
  }
  const firstRecurrence = recurrences[0];

  // activity_start_time: "09:00:00"
  // activity_end_time: "11:00:00"

  if (!firstRecurrence.activity_start_time || !firstRecurrence.activity_end_time) {
    return <p>No Times</p>;
  }

  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const startNeedsLeadingZero = firstRecurrence.activity_start_time.length === 7;
  const endNeedsLeadingZero = firstRecurrence.activity_end_time.length === 7;

  if (startNeedsLeadingZero) {
    firstRecurrence.activity_start_time = "0" + firstRecurrence.activity_start_time;
  }

  if (endNeedsLeadingZero) {
    firstRecurrence.activity_end_time = "0" + firstRecurrence.activity_end_time;
  }

  // The date is arbitrary, we just need the time
  const start = new Date(firstRecurrence.activity_start_date + "T" + firstRecurrence.activity_start_time + "Z");
  const end = new Date(firstRecurrence.activity_end_date + "T" + firstRecurrence.activity_end_time + "Z");

  if (start.toString() === "Invalid Date" || end.toString() === "Invalid Date") {
    // console.log(firstRecurrence);
    return null;
  }

  const startTime = timeFormatter.format(start);
  const endTime = timeFormatter.format(end);

  return (
    <div>
      <p className={cn("flex", "items-center", "gap-1", "text-sm")} suppressHydrationWarning>
        <Clock className={cn("w-4")} />
        {startTime} - {endTime}
      </p>
    </div>
  );
}
