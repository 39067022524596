import { useState } from "react";
import { ActivityLocation } from "@/components/activities/activity-location";
import { ActivityPricing } from "@/components/activities/activity-pricing";
import { ActivityDates } from "@/components/activities/activity-dates";
import { ActivityTimes } from "@/components/activities/activity-times";
import { ActivityDays } from "@/components/activities/activity-days";
import { Button } from "~/components/ui/button";

export function ActivityCard({ activity }) {
  const [showDrescription, setShowDescription] = useState(false);

  return (
    <>
      <div className="flex">
        <div className="flex-grow">
          <h2 className="text-lg font-semibold flex flex-col">
            <span className="text-gray-400 text-sm">#{activity.activity_number}</span>
            <span className="text-balance">{activity.activity_name}</span>
          </h2>
          {activity.catalog_description && (
            <button
              className="text-sm text-gray-400"
              onClick={() => setShowDescription(!showDrescription)}
              data-no-index
            >
              {showDrescription ? "Hide Description" : "Show Description"}
            </button>
          )}
        </div>
      </div>
      {showDrescription && (
        <p className="text-sm pt-6 text-pretty" dangerouslySetInnerHTML={{ __html: activity.catalog_description }}></p>
      )}
      <div className="pt-6 flex-col md:flex-row flex md:items-end gap-2 lg:gap-6" data-no-index>
        <div className="flex-grow ">
          <ActivityDates activity={activity} />
          <ActivityTimes activity={activity} />
          <ActivityLocation activity={activity} />
          <div className="pt-1">
            <ActivityDays activity={activity} />
          </div>
        </div>
        <hr />
        <div className="flex flex-col md:justify-end md:text-right gap-3">
          <ActivityPricing activity={activity} />
          <Button asChild size="sm">
            <a href={activity.public_url} target="_blank">
              Register
            </a>
          </Button>
        </div>
      </div>
    </>
  );
}
